<template>
<div id="">
    <div class="search-row">
      <Navbar navbarClass="transparent"></Navbar>
      <v-container grid-list-xs,sm,md,lg,xl>
        <v-layout  wrap justify-center>
          <h1 class="display-3 blog-title">
              {{$t('hiw.hiw')}}
            </h1>
        </v-layout>
      </v-container>
    </div>


  <router-view class=""></router-view>
</div>
</template>
<script>
export default {
  name: "",
  data: () => ({

  }),
  created() {
    //do something after creating vue instance
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    })

  }
}
</script>
<style lang="scss" scoped>
.search-row {
    background: rgba(255,255,255,0),;
    background: rgba(102,102,102,0.5), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,q_auto:eco,w_1400/v1537779939/blog/desk.jpg") center/cover no-repeat;
    background: -moz-linear-gradient(top, rgba(102,102,102,0.5) 0%, rgba(133,133,133,0) 40%, rgba(255,255,255,0) 100%), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,q_auto:eco,w_1400/v1537779939/blog/desk.jpg") center/cover no-repeat;
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(102,102,102,0.5)), color-stop(40%, rgba(133,133,133,0)), color-stop(100%, rgba(255,255,255,0))), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,q_auto:eco,w_1400/v1537779939/blog/desk.jpg") center/cover no-repeat;
    background: -webkit-linear-gradient(top, rgba(102,102,102,0.5) 0%, rgba(133,133,133,0) 40%, rgba(255,255,255,0) 100%), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,q_auto:eco,w_1400/v1537779939/blog/desk.jpg") center/cover no-repeat;
    background: -o-linear-gradient(top, rgba(102,102,102,0.5) 0%, rgba(133,133,133,0) 40%, rgba(255,255,255,0) 100%), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,q_auto:eco,w_1400/v1537779939/blog/desk.jpg") center/cover no-repeat;
    background: -ms-linear-gradient(top, rgba(102,102,102,0.5) 0%, rgba(133,133,133,0) 40%, rgba(255,255,255,0) 100%), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,q_auto:eco,w_1400/v1537779939/blog/desk.jpg") center/cover no-repeat;
    background: linear-gradient(to bottom, rgba(102,102,102,0.5) 0%, rgba(133,133,133,0) 40%, rgba(255,255,255,0) 100%), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,q_auto:eco,w_1400/v1537779939/blog/desk.jpg") center/cover no-repeat;
}
.blog-title {
    margin: auto;
    padding-top: 15vh;
    margin-bottom: 10vh;
    text-align: center;
    color: #ffffff;
    text-shadow: 0 2px 4px rgba(0,0,0,.6);
}
.careers div {
    margin: 1rem 0;
}
</style>
