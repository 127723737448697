<template>
<div id="">
  <v-container grid-list-xl>
    <v-breadcrumbs divider="">
      <v-icon slot="divider">chevron_right</v-icon>
      <v-breadcrumbs-item
      v-for="(item,n) in items"
      :key="n"
      :disabled="item.disabled"
      >
      <a :href="item.href">{{ item.text }}</a>
    </v-breadcrumbs-item>
  </v-breadcrumbs>
    <v-layout  wrap justify-center>
      <v-flex md8 sm9 xs10>
      <div class="how-it-works">

        <h3 class="title">1. {{$t('hiw.title1')}} </h3>
        <div>
          {{$t('hiw.text11')}}
        </div>
        <div>
          {{$t('hiw.text12')}}
        </div>
        <div>
          {{$t('hiw.text13')}}<span class="link" @click="showVerification=!showVerification">{{$t('hiw.link1')}}</span>{{$t('hiw.text14')}}
        </div>
        <div class="" v-if="showVerification">
          <div class="question-wrapper">
            <div class="">
              {{$t('hiw.text15')}}
            </div>
            <ol>
              <li>
                <div>
                  {{$t('hiw.li1')}}
                </div>
              </li>
              <li>
                <div>
                  {{$t('hiw.li2')}}
                </div>
              </li>
              <li>
                <div>
                  {{$t('hiw.li3')}}
                </div>
              </li>
              <li>
                <div>
                  {{$t('hiw.li4')}}
                </div>
              </li>
            </ol>
            <div>
              {{$t('hiw.text16')}}
            </div>
          </div>
        </div>
        <h3 class="title">2. {{$t('hiw.title2')}}</h3>
        <div>
          {{$t('hiw.text21')}}
        </div>
        <div>
          {{$t('hiw.text22')}}
        </div>
        <div class="">
          {{$t('hiw.text23')}}
        </div>
        <div class="">
          {{$t('hiw.text24')}}
        </div>
        <div class="">
          {{$t('hiw.text25')}}
        </div>
        <div class="">
          {{$t('hiw.text26')}}
        </div>
        <h3 class="title">3. {{$t('hiw.title3')}}</h3>
        <div>
          {{$t('hiw.text31')}}
        </div>
        <div>
          {{$t('hiw.text32')}}
        </div>
        <div>
          {{$t('hiw.text33')}}
        </div>
        <h3 class="title">4. {{$t('hiw.title4')}}</h3>
        <div>
          {{$t('hiw.text41')}}
        </div>
        <div>
          {{$t('hiw.text42')}}
        </div>
        <h3 class="title">5. {{$t('hiw.title5')}}</h3>
        <div>
          {{$t('hiw.text51')}}
        </div>
        <div>
          {{$t('hiw.text52')}}
        </div>
        <h3 class="title">6. {{$t('hiw.title6')}}</h3>

        <div>
          {{$t('hiw.text61')}}
        </div>
        <v-btn color="primary" flat href='/faq'>{{$t('hiw.learnMore')}}</v-btn>
        <v-divider></v-divider>
        <div>
          <strong>{{$t('hiw.q1')}}</strong>
        </div>
        <div>
          {{$t('hiw.a1')}}
        </div>
        <div class="">
          {{$t('hiw.a2')}}
        </div>
        <div>
          {{$t('hiw.a3')}}
      </div>
      </div>
    </v-flex>
    </v-layout>
  </v-container>
</div>

</template>
<script>
export default {
  name: "",
  data: () => ({
    showVerification:false,
    items: [
        {
          text: 'Home',
          disabled: false,
          href: '/'
        },
        {
          text: 'How it Works',
          disabled: false,
          href: '/hiw'
        },
        {
          text: 'Tenants',
          disabled: false,
          href: '/hiw/tenants'
        }
      ]
  }),
  created() {
    //do something after creating vue instance
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    })

  }
}
</script>
<style lang="scss" scoped>
.search-row {
    background: rgba(255,255,255,0),;
    background: rgba(102,102,102,0.5), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,q_auto:eco,w_1400/v1537781070/blog/student-team.jpg") center/cover no-repeat;
    background: -moz-linear-gradient(top, rgba(102,102,102,0.5) 0%, rgba(133,133,133,0) 40%, rgba(255,255,255,0) 100%), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,q_auto:eco,w_1400/v1537781070/blog/student-team.jpg") center/cover no-repeat;
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(102,102,102,0.5)), color-stop(40%, rgba(133,133,133,0)), color-stop(100%, rgba(255,255,255,0))), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,q_auto:eco,w_1400/v1537781070/blog/student-team.jpg") center/cover no-repeat;
    background: -webkit-linear-gradient(top, rgba(102,102,102,0.5) 0%, rgba(133,133,133,0) 40%, rgba(255,255,255,0) 100%), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,q_auto:eco,w_1400/v1537781070/blog/student-team.jpg") center/cover no-repeat;
    background: -o-linear-gradient(top, rgba(102,102,102,0.5) 0%, rgba(133,133,133,0) 40%, rgba(255,255,255,0) 100%), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,q_auto:eco,w_1400/v1537781070/blog/student-team.jpg") center/cover no-repeat;
    background: -ms-linear-gradient(top, rgba(102,102,102,0.5) 0%, rgba(133,133,133,0) 40%, rgba(255,255,255,0) 100%), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,q_auto:eco,w_1400/v1537781070/blog/student-team.jpg") center/cover no-repeat;
    background: linear-gradient(to bottom, rgba(102,102,102,0.5) 0%, rgba(133,133,133,0) 40%, rgba(255,255,255,0) 100%), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,q_auto:eco,w_1400/v1537781070/blog/student-team.jpg") center/cover no-repeat;
}
.blog-title {
    margin: auto;
    padding-top: 15vh;
    margin-bottom: 10vh;
    text-align: center;
    color: #ffffff;
    text-shadow: 0 2px 4px rgba(0,0,0,.6);
}
.how-it-works{
  text-align: justify;
}
.how-it-works div {

    margin: 1rem 0;
}

.link{
  color: #00bfff;
  text-decoration: underline;
  cursor: pointer;
}
</style>
