<template>
<div id="">
  <v-container grid-list-xl>
    <v-breadcrumbs divider="">
      <v-icon slot="divider">chevron_right</v-icon>
      <v-breadcrumbs-item v-for="(item,n) in items" :key="n" :disabled="item.disabled">
        <a :href="item.href">{{ item.t }}</a>
      </v-breadcrumbs-item>
    </v-breadcrumbs>
    <v-layout  wrap justify-center>
      <v-flex md8 sm9 xs10>
        <div class="how-it-works">

          <h3 class="title">1. {{$t('llhiw.title1')}} </h3>
          <div>
            {{$t('llhiw.t11')}}
          </div>
          <div>
            {{$t('llhiw.t12')}}
          </div>
          <div>
            {{$t('llhiw.t13')}}
          </div>
          <div class="">
            <div class="">
              {{$t('llhiw.t14')}}
            </div>
            <ul>
              <li>
                <div>
                  {{$t('llhiw.li141')}}
                </div>
              </li>
              <li>
                <div>
                  {{$t('llhiw.li142')}}
                </div>
              </li>
              <li>
                <div>
                  {{$t('llhiw.li143')}}
                </div>
              </li>
            </ul>
          </div>
          <h3 class="title">2. {{$t('llhiw.title2')}}</h3>
          <div>
            {{$t('llhiw.t21')}}
          </div>
          <div>
            {{$t('llhiw.t22')}}
          </div>
          <h3 class="title">3. {{$t('llhiw.title3')}}</h3>
          <div>
            {{$t('llhiw.t31')}}
          </div>
          <div>
            {{$t('llhiw.t32')}}
          </div>
          <div>
            {{$t('llhiw.t33')}}
          </div>
          <div>
            {{$t('llhiw.t34')}}
          </div>
          <div>
            {{$t('llhiw.t35')}}
          </div>
          <div>
            {{$t('llhiw.t36')}}
          </div>
          <h3 class="title">4. {{$t('llhiw.title4')}}</h3>
          <div>
            {{$t('llhiw.t41')}}
          </div>
          <div>
            {{$t('llhiw.t42')}}
          </div>
          <div>
            {{$t('llhiw.t43')}}
          </div>
          <h3 class="title">5. {{$t('llhiw.title5')}}</h3>
          <h4 class="subtitle my-3">5.1 {{$t('llhiw.st51')}}</h4>
          <h5 class="subheading">5.1.1 {{$t('llhiw.sst511')}}</h5>
          <div>
            {{$t('llhiw.t5111')}}
          </div>
          <h5 class="subheading">5.1.2 {{$t('llhiw.sst512')}}</h5>
          <div>
            {{$t('llhiw.t5121')}}
          </div>
          <div>
            {{$t('llhiw.t5122')}}
          </div>
          <div>
            {{$t('llhiw.t5123')}}
          </div>
          <h5 class="subheading">5.1.3 {{$t('llhiw.sst513')}}</h5>
          <div>
            {{$t('llhiw.t5131')}}
          </div>
          <h4 class="subtitle my-2">5.2 {{$t('llhiw.st52')}}</h4>
          <div>
            {{$t('llhiw.t521')}}
          </div>

          <h5 class="subheading">5.2.1 {{$t('llhiw.sst521')}}</h5>
          <div>
            {{$t('llhiw.t5211')}}
          </div>
          <h5 class="subheading">5.2.2 {{$t('llhiw.sst522')}}</h5>
          <div>
            {{$t('llhiw.t5221')}}
          </div>
          <h4 class="subtitle my-2">5.3 {{$t('llhiw.st53')}}</h4>

          <h5 class="subheading">5.3.1 {{$t('llhiw.sst531')}}</h5>
          <div>
            {{$t('llhiw.t5311')}}

          </div>
        </div>


      </v-flex>
    </v-layout>
  </v-container>
</div>
</template>
<script>
export default {
  name: "",
  data: () => ({
    showVerification: false,
    items: [{
        t: 'Home',
        disabled: false,
        href: '/'
      },
      {
        t: 'How it Works',
        disabled: false,
        href: '/hiw'
      },
      {
        t: 'Landlords',
        disabled: false,
        href: '/hiw/landlords'
      }
    ]
  }),
  created() {
    //do something after creating vue instance
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    })

  }
}
</script>
<style lang="scss" scoped>
.search-row {
    background: rgba(255,255,255,0),;
    background: rgba(102,102,102,0.5), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,q_auto:eco,w_1400/v1537781070/blog/student-team.jpg") center/cover no-repeat;
    background: -moz-linear-gradient(top, rgba(102,102,102,0.5) 0%, rgba(133,133,133,0) 40%, rgba(255,255,255,0) 100%), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,q_auto:eco,w_1400/v1537781070/blog/student-team.jpg") center/cover no-repeat;
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(102,102,102,0.5)), color-stop(40%, rgba(133,133,133,0)), color-stop(100%, rgba(255,255,255,0))), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,q_auto:eco,w_1400/v1537781070/blog/student-team.jpg") center/cover no-repeat;
    background: -webkit-linear-gradient(top, rgba(102,102,102,0.5) 0%, rgba(133,133,133,0) 40%, rgba(255,255,255,0) 100%), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,q_auto:eco,w_1400/v1537781070/blog/student-team.jpg") center/cover no-repeat;
    background: -o-linear-gradient(top, rgba(102,102,102,0.5) 0%, rgba(133,133,133,0) 40%, rgba(255,255,255,0) 100%), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,q_auto:eco,w_1400/v1537781070/blog/student-team.jpg") center/cover no-repeat;
    background: -ms-linear-gradient(top, rgba(102,102,102,0.5) 0%, rgba(133,133,133,0) 40%, rgba(255,255,255,0) 100%), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,q_auto:eco,w_1400/v1537781070/blog/student-team.jpg") center/cover no-repeat;
    background: linear-gradient(to bottom, rgba(102,102,102,0.5) 0%, rgba(133,133,133,0) 40%, rgba(255,255,255,0) 100%), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,q_auto:eco,w_1400/v1537781070/blog/student-team.jpg") center/cover no-repeat;
}
.blog-title {
    margin: auto;
    padding-top: 15vh;
    margin-bottom: 10vh;
    t-align: center;
    color: #ffffff;
    t-shadow: 0 2px 4px rgba(0,0,0,.6);
}
.how-it-works {
    t-align: justify;
}
.how-it-works div {

    margin: 1rem 0;
}

.link {
    color: #00bfff;
    t-decoration: underline;
    cursor: pointer;
}
</style>
